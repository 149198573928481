import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";


export default function CreateFlota() {


  const navigate = useNavigate();
  const { http } = AuthUser();
  const [registerInput, setRegister] = useState(
    {
      id: '',
      contratos_id: '',
      ciudads_id: '',
      patente: '',
      tipo: '',
      marcas_id: '',
      modelo: '',
      año: '',
      fecha_entrega: '',
      meses_contrato: '',
      uf_mes: '',
      //uf_UltimoDiaMes:'',
      //fecha_facturacion:'',     
      f_ingreso_taller:'',
      f_salida_taller:'',
     // dias_taller:'',
     // dias_mes:'',
     // total_dias:'',
      //total_mes:'',
      estados_id:'',
      observacion: '',
      error_list: [],
    });

  const [loading, setLoading] = useState(true);
  const [listaCiudades, setListaCiudades] = useState([]);
  const [listaContratos, setListaContratos] = useState([]);
  const [listaMarcas, setListaMarcas] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);

  useEffect(() => {
    http.get(`/listaCiudades`).then(res => {
      if (res.status === 200) {
        setListaCiudades(res.data.ciudades)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listContrato`).then(res => {
      if (res.status === 200) {
        setListaContratos(res.data.contratos)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listaEstados`).then(res => {
      if (res.status === 200) {
        setListaEstados(res.data.estado)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listaMarcas`).then(res => {
      if (res.status === 200) {
        setListaMarcas(res.data.marcas)
      } setLoading(false);
    });
  }, []);

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      contratos_id: registerInput.contratos_id,
      ciudads_id: registerInput.ciudads_id,
      patente: registerInput.patente,
      tipo: registerInput.tipo,
      marcas_id: registerInput.marcas_id,
      modelo: registerInput.modelo,
      año: registerInput.año,
      fecha_entrega: registerInput.fecha_entrega,
      meses_contrato: registerInput.meses_contrato,
      uf_mes: registerInput.uf_mes,

      //uf_UltimoDiaMes:registerInput.uf_UltimoDiaMes,
      //fecha_facturacion:registerInput.fecha_facturacion,     
      f_ingreso_taller:registerInput.f_ingreso_taller,
      f_salida_taller:registerInput.f_salida_taller,
     // dias_taller:registerInput.dias_taller,
      //dias_mes:registerInput.dias_mes,
      //total_dias:registerInput.total_dias,
      //total_mes:registerInput.total_mes,
      estados_id:registerInput.estados_id,

      observacion: registerInput.observacion,
      //creado_por:registerInput.creado_por,

    };
    http.post('/createFlota', data).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/listFlota')
      } else {
        setRegister({ ...registerInput, error_list: res.data.validate_errors })
      }


    });
    if (loading) {
      <h4>Trayendo ciudad...</h4>
    }
  }

  const patterns = { name: /^[A-Za-z]+$/i };

  return (

    <body>

      <div className="container mt-4">
        <div className="card bg-secondary text-black">
        <form onSubmit={registerSubmit}>
          <div className="card-body">
            {/* <h2>Ingresar Nueva Patente al Contrato</h2> */}
          
            {/* <!-- Multiple inputs 1 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="contratos_id" placeholder="Numero Contrato" onChange={handleInput}>
                      <option defaultValue disable>Elija Contrato..</option>
                      {listaContratos.map((items) => (<option key={items.id} value={items.idc}> {items.numContrato}-{items.nomEmp}</option>))}
                    </select>
                    <label className="form-label">Elija Numero de Contrato:</label>
                    <span className="badge bg-danger">{registerInput.error_list.contratos_id}</span>
                  </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="ciudads_id" placeholder="Ciudad" onChange={handleInput}>
                      <option defaultValue disable>Elija Ciudad Opciones..</option>
                      {listaCiudades.map((items) => (<option key={items.id} value={items.id}>{items.ciudad}</option>))}
                    </select>
                    <label className="form-label">Ciudad:</label>
                    <span className="badge bg-danger">{registerInput.error_list.ciudads_id}</span>
                  </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Patente" name="patente" id="patente"
                    style={{ textTransform: 'uppercase'}}  maxLength='6' minLength='6'
                      onChange={handleInput} value={registerInput.patente}   />
                    <label className="form-label">Patente:</label>
                    <span className="badge bg-danger">{registerInput.error_list.patente}</span>
                  </div>

                </div>
                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Tipo" name="tipo"
                      onChange={handleInput} value={registerInput.tipo} />
                    <label className="form-label">Tipo:</label>
                    <span className="badge bg-danger">{registerInput.error_list.tipo}</span>
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 2 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
              <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="marcas_id" placeholder="Marcas" onChange={handleInput}>
                      <option defaultValue disable>Elija Marca..</option>
                      {listaMarcas.map((items) => (<option key={items.id} value={items.id}>{items.marca}</option>))}
                    </select>
                    <label className="form-label">Marca Unidad:</label>
                    <span className="badge bg-danger">{registerInput.error_list.marcas_id}</span>
                  </div>
                  </div>

             
                  <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Modelo" name="modelo"
                      onChange={handleInput} value={registerInput.modelo} />
                    <label className="form-label">Modelo:</label>
                    <span className="badge bg-danger">{registerInput.error_list.modelo}</span>
                    </div>
                </div>



                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Año" name="año"
                      onChange={handleInput} value={registerInput.año} />
                    <label className="form-label">Año:</label>
                    <span className="badge bg-danger">{registerInput.error_list.año}</span>
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="date" className="form-control" placeholder="Fecha Entrega" name="fecha_entrega"
                      onChange={handleInput} value={registerInput.fecha_entrega} />
                    <label className="form-label">Fecha Entrega:</label>
                    <span className="badge bg-danger">{registerInput.error_list.fecha_entrega}</span>
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 3 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" placeholder="Meses Contrato" name="meses_contrato"
                      onChange={handleInput} value={registerInput.meses_contrato} />
                    <label className="form-label">Meses Contrato:</label>
                    <span className="badge bg-danger">{registerInput.error_list.meses_contrato}</span>
                    </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" placeholder="UF Arriendo" name="uf_mes"
                      onChange={handleInput} value={registerInput.uf_mes} />
                    <label className="form-label">UF Arriendo:</label>
                    <span className="badge bg-danger">{registerInput.error_list.uf_mes}</span>
                    </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="estados_id" placeholder="Estado" onChange={handleInput}>
                      <option defaultValue disable>Elija un estado..</option>
                      {listaEstados.map((items) => (<option key={items.id} value={items.id}>{items.estado}</option>))}
                    </select>
                    <label className="form-label">Estado:</label>
                    {/* <span className="badge bg-danger">{registerInput.error_list.estados_id}</span> */}
                  </div>
                </div>

              <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Observaciones" name="observacion"
                      onChange={handleInput} value={registerInput.observacion} />
                    <label>Observaciones (Opcional)</label>
                  </div>

                </div>







              </div>


              
            </div>

            
            {/* <!-- Multiple inputs 4 --> */}
      

            
            <div className="d-flex">
  <div className="btn-group">
  <button type="button" className="btn"></button>
  <button type="submit" data-bs-toggle="tooltip" title="Ingresar Flota" className="btn btn-outline-secondary"><h3><FcAcceptDatabase/></h3></button>
  <button type="button" className="btn"></button>
  <button type="submit" className="btn btn-outline-secondary">
  <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listFlota"><h3><FcDeleteDatabase/></h3></Link></button>
  <button type="button" className="btn"></button>
  
  </div>
</div>



          </div>
</form>
        </div>
      </div>

    </body>


  )
}