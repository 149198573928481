import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";


export default function EditContrato() {

  const navigate = useNavigate();
  const { id } = useParams();
  const { http } = AuthUser();
  const [trayendo, setTrayendo] = useState([true]);
  const [registerInput, setRegister] = useState({
    id: null, numContrato: '', meses_contrato: '', fecha_inicio: '', fecha_termino: '', plazoSiniestro: '',
    deducibleAccesorios: '', deducibleRobo: '', Reemplazos: '', porcentajeDevolucion: '',
    administracionTag: ''
    , administracionMulta: '',
    empresas_id: '', nombreEmpresa: '', users_id: '',vigente:'', name: '', creado_por: '', error_list: [],
  });

  const [error, setError] = useState([]);

  const [loading, setLoading] = useState(true);
  const [listaUsuario, setListaUsuarios] = useState([]);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaContrato, setListaContrato] = useState([]);

  useEffect(() => {
    http.get(`/listUsers`).then(res => {
      if (res.status === 200) {
        setListaUsuarios(res.data.users)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listaEmpresas`).then(res => {
      if (res.status === 200) {
        setListaEmpresa(res.data.empresas)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listContrato`).then(res => {
      if (res.status === 200) {
        setListaContrato(res.data.contratos)
      } setLoading(false);
    });
  }, []);


  useEffect(() => {
    //Ruta que trae los datos para editar con el id
    http.get(`/editContrato/${id}`).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        setRegister(res.data.contratos);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listContrato");

      }
      setTrayendo(false);
    }

    );

  }, [id, navigate]);


  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const editaContrato = (e) => {
    e.preventDefault();
    const data = {
      numContrato: registerInput.numContrato,
      meses_contrato: registerInput.meses_contrato,
      fecha_inicio: registerInput.fecha_inicio,
      fecha_termino: registerInput.fecha_termino,
      plazoSiniestro: registerInput.plazoSiniestro,
      deducibleAccesorios: registerInput.deducibleAccesorios,
      deducibleRobo: registerInput.deducibleRobo,
      Reemplazos: registerInput.Reemplazos,
      porcentajeDevolucion: registerInput.porcentajeDevolucion,
      empresas_id: registerInput.empresas_id,
      nombreEmpresa: registerInput.nombreEmpresa,
      administracionTag: registerInput.administracionTag,
      administracionMulta: registerInput.administracionMulta,
      users_id: registerInput.users_id,   //users_id: registerInput.users_id, //id usuario a quien se le asignara la empresa
      vigente:registerInput.vigente,
      name: registerInput.name,
      creado_por: registerInput.creado_por,

    };
    //Ruta para cambiar los datos con el id.
    http.put(`/editarContrato/${id}`, data).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/listContrato');
        // setError([]);
      }
      else if (res.data.status === 422) {
        swal("Datos deben estar completos", "", "error");
        setError(res.data.errors);
      }
      else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate('/listaEmpresas');
      }

    });
  }
  if (trayendo) {
    <h4>Trayendo datos para Editar...</h4>
  }

  if (loading) {
    <h4>Trayendo ciudad...</h4>
  }

  return (


    <body>
      <div className="container mt-4">
        <div className="card bg-secondary text-black">
          <form onSubmit={editaContrato}>
            <div className="card-body">
              {/* <h2>Ingresar Nueva Patente al Contrato</h2> */}

              {/* <!-- Multiple inputs 1 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" className="form-control" id="numContrato" placeholder="N°Contrato"
                        name="numContrato" onChange={handleInput} value={registerInput.numContrato} />
                      <label className="form-label">N°Contrato:</label>
                      <small className="badge bg-danger">{error.numContrato}</small>
                    </div>
                  </div>

                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="number" className="form-control" id="meses_contrato" placeholder="Meses Contrato"
                        name="meses_contrato" onChange={handleInput} value={registerInput.meses_contrato} />
                      <label className="form-label">Meses Contrato</label>
                      <small className="badge bg-danger">{error.meses_contrato}</small>
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="date" className="form-control" id="fecha_inicio" placeholder="Fecha Inicio"
                        name="fecha_inicio" onChange={handleInput} value={registerInput.fecha_inicio} />
                      <label className="form-label">Fecha Inicio</label>
                      <small className="badge bg-danger">{error.fecha_inicio}</small>
                    </div>

                  </div>
                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="date" className="form-control" id="fecha_termino" placeholder="Fecha Termino"
                        name="fecha_termino" onChange={handleInput} value={registerInput.fecha_termino} />
                      <label className="form-label">Fecha Termino</label>
                      <small className="badge bg-danger">{error.fecha_termino}</small>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Multiple inputs 2 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <input type="number" className="form-control" id="plazoSiniestro" placeholder="Plazo ingreso Siniestro - Horas"
                        name="plazoSiniestro" onChange={handleInput} value={registerInput.plazoSiniestro} min={12} />
                      <label className="form-label">Plazo ingreso Siniestro - Horas</label>
                      <small className="badge bg-danger">{error.plazoSiniestro}</small>
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="deducibleAccesorios" placeholder="UF-Deducible Accesorios"
          name="deducibleAccesorios" onChange={handleInput} value={registerInput.deducibleAccesorios}  min={1} />
                      <label className="form-label">UF-Deducible Accesorios</label>
                      <small className="badge bg-danger">{error.deducibleAccesorios}</small> 
                    </div>
                  </div>



                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="deducibleRobo" placeholder="UF-Deducible Robo" 
          name="deducibleRobo" onChange={handleInput} value={registerInput.deducibleRobo} min={1} />
                      <label className="form-label">UF-Deducible Robo</label>
                      <small className="badge bg-danger">{error.deducibleRobo}</small> 
                    </div>
                  </div>

                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="Reemplazos" placeholder="% Reemplazos"
          name="Reemplazos" onChange={handleInput} value={registerInput.Reemplazos}  min={1} />
                      <label className="form-label">% Reemplazos</label>
                      <small className="badge bg-danger">{error.Reemplazos}</small> 
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Multiple inputs 3 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="porcentajeDevolucion" placeholder="% Devolucion" 
          name="porcentajeDevolucion" onChange={handleInput} value={registerInput.porcentajeDevolucion}  min={1} />
                      <label className="form-label">% Devolucion Anticipada</label>
                      <small className="badge bg-danger">{error.porcentajeDevolucion}</small> 
                    </div>
                  </div>

                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="administracionTag" placeholder="% Administracion Tag"
           name="administracionTag" onChange={handleInput} value={registerInput.administracionTag}  min={1} />
                      <label className="form-label">% administracion Tag</label>
                      <small className="badge bg-danger">{error.administracionTag}</small>     
                    </div>
                  </div>

                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="administracionMulta" placeholder="% Administracion Multa"
           name="administracionMulta" onChange={handleInput} value={registerInput.administracionMulta}  min={1} />
                      <label className="form-label">% administracion Multa</label>
                      <small className="badge bg-danger">{error.administracionMulta}</small>  
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <select className="form-control" id="empresas_id" placeholder="Empresa"
           name="empresas_id" onChange={handleInput}>
             <option value={registerInput.empresas_id}>{registerInput.empresas_id}</option>
            {listaEmpresa.map((items) => (<option key={items.id} value={items.id}>{items.nombreEmpresa}</option>))}
    </select>
                      <label className="form-label">Nombre Empresa:</label>
                      <small className="badge bg-danger">{error.empresas_id}</small> 
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                    <select className="form-control" id="users_id" placeholder="Asigne Ejecutivo"
           name="users_id" onChange={handleInput}>
            <option value={registerInput.users_id}>{registerInput.users_id}</option>
            {listaUsuario.map((items) => (<option key={items.id} value={items.id}>{items.name}</option>))}
    </select>
                      <label className="form-label">Ejecutivo Asignado:</label>
                      <small className="badge bg-danger">{error.users_id}</small> 
                    </div>
                  </div>


                  <div className="col-sm-3 bg-ligh">
                    <div className="form-floating mb-3 mt-3">
                      <select className="form-control" id="vigente" placeholder="Estado Contrato" value={registerInput.vigente}
                        name="vigente" onChange={handleInput}>
                          <option selected disabled>Indique estado..</option>                       
                        <option value="vigente">vigente</option>
                        <option value="no vigente">no vigente</option>

                      </select>
                      <label className="form-label">Estado del Contrato:</label>
                      <small className="badge bg-danger">{error.vigente}</small> 
                    </div>
                  </div>





                  <div className="col-sm-3 bg-ligh">

                    <div className="d-flex">
                      <div className="btn-group">
                        <button type="button" className="btn"></button>
                        <button type="submit" data-bs-toggle="tooltip" title="Ingresar Flota" className="btn btn-outline-secondary"><h1><FcAcceptDatabase /></h1></button>
                        <button type="button" className="btn"></button>
                        <button type="submit" className="btn btn-outline-secondary">
                          <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listContrato"><h1><FcDeleteDatabase /></h1></Link></button>
                        <button type="button" className="btn"></button>

                      </div>
                    </div>

                  </div>



                </div>
              </div>

            </div>
          </form>
        </div>
      </div>

    </body>

  )
}