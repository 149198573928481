import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAddDatabase } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcDeleteRow } from "react-icons/fc";
import { FcMenu } from "react-icons/fc";

import { GoKebabHorizontal } from "react-icons/go";

import Table from 'react-bootstrap/Table';

import Dropdown from 'react-bootstrap/Dropdown';


export default function ListUser() {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState([]);
  const [tablaUsuarios, setTablaUsuarios] = useState([]); //Lista los datos de manera estatica
  const [busqueda, setBusqueda] = useState("");
  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    await http.get('/listUsers').then((res) => {
      //  console.log(res.data);        
      if (res.status === 200) {
        setUserProfile(res.data.users);
        setTablaUsuarios(res.data.users);
      }
      setLoading(false);
    });
  };

  const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value)
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaUsuarios.filter((elementos) => {
      if (elementos.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;
      } else if (elementos.email.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ) {
        return elementos;
      }
      return false;
    });
    setUserProfile(resultadosBusqueda);
  }


  const eliminarUsuario = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Eliminando";

    http.delete(`/eliminarUsuario/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");

        thisClicked.closest("tr").remove();
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Eliminar";

      }

    });

  }

  var listaUsuarios_HTMLTABLE = "";
  if (loading) {
    return <div className="center">
      <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>
  } else {

    listaUsuarios_HTMLTABLE =
      userProfile.map((item) => {
        return (

          <tr key={item.id} >
            <td hidden>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.cargo}</td>
            <td>{item.role}</td>

            {/* <td align="center"><Link title="Editar Usuario" to={`/editaUsuarios/${item.id}`} ><h3><FcEditImage/></h3></Link></td>
                        <td align="center" >
                    <Link title="Eliminar Usuario" to="" onClick={(e)=>eliminarUsuario(e,item.id)} ><h3><FcDeleteRow/></h3></Link>
                   </td>   */}

            <td align="center">
              <Dropdown><Dropdown.Toggle variant="light">
                <FcMenu />
              </Dropdown.Toggle> <Dropdown.Menu>
                  <Dropdown.Item href={`/editaUsuarios/${item.id}`}><FcEditImage />Editar </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => eliminarUsuario(e, item.id)}><FcDeleteRow />Eliminar</Dropdown.Item>
                </Dropdown.Menu></Dropdown>
            </td>






          </tr>
        )
      });
  }



  return (
    <body>

      <nav className="navbar navbar-light" >
        <div className="container-fluid">
          <Link data-bs-toggle="tooltip" title="Crear Nuevo Usuario" className="navbar-brand"
            to="/register"><h3><FcAddDatabase /></h3> </Link>

          <div className="d-flex">
            <input type="text" className="form-control" id="pwd" value={busqueda}
              placeholder="Nombre / Usuario " onChange={handleChange} />

          </div>
        </div>
      </nav>





      <div className="container mt-12">
        <Table striped bordered hover>
          <thead className='table-dark' >




            <tr align="center">


              <th>Nombre</th>
              <th>Email</th>
              <th>Cargo</th>
              <th>Role</th>

              <th> <GoKebabHorizontal /></th>

            </tr>
          </thead>



          <tbody>
            {listaUsuarios_HTMLTABLE}
          </tbody>
        </Table>
      </div>

    </body>
  );
}