import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
//import { FaUserPlus } from "react-icons/fa";
import { FcAddDatabase } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcDeleteRow } from "react-icons/fc";
//import { FcSearch } from "react-icons/fc";
import { FcMenu } from "react-icons/fc";
import { GoKebabHorizontal } from "react-icons/go";
import Table from 'react-bootstrap/Table';
import Pagination from "../component/Paginacion";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




export default function ListEmpresa() {

  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setSmShow(false);

  // +++++++++++++++++++++++++++++++++ paginacion +++++++++++++++++++++++
 // const [posts, setPosts] = useState([])
  const [loadings, setLoadings] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10) //7 Per Page
  //+++++++++++++++++++++++++++++++++++++


  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState([]);
  const [tablaEmpresa, setTablaEmpresa] = useState([]); //Lista los datos de manera estatica
  const [busqueda, setBusqueda] = useState("");
  const [contarEmpresa, setcontarEmpresa] = useState([]);
  useEffect(() => {
    fetchUserProfile();
  }, []);
  const fetchUserProfile = async () => {
    await http.get('/listaEmpresas').then((res) => {
      console.log(res.data.contar);
      if (res.status === 200) {
        setUserProfile(res.data.empresas);
        setcontarEmpresa(res.data.contar);
        setTablaEmpresa(res.data.empresas);
        setLoadings(false);
      }
      setLoading(false);
    });
  };

  const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value)
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaEmpresa.filter((elementos) => {
      if (elementos.nombreEmpresa.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;
      } else if (elementos.rut.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ) {
        return elementos;
      }
      return false;
    });
    setUserProfile(resultadosBusqueda);
  }

  const eliminarEmpresa = async (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Eliminando";

    await http.delete(`/eliminarEmpresa/${id}`).then(res => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");

        thisClicked.closest("tr").remove();
      }
      else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Eliminar";

      }

    });

  }

  if (loadings && userProfile.length === 0) {
    return <h2>Loading...</h2>
  }
  //Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = userProfile.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(userProfile.length / postsPerPage)



  var listaUsuarios_HTMLTABLE = "";
  if (loading) {
    return <div className="center">
      <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>
  } else {

    listaUsuarios_HTMLTABLE =
      currentPosts.map((item) => {
        return (
          <tr key={item.id}>
            <td hidden>{item.id}</td>
            <td>{item.nombreEmpresa}</td>
            <td>{item.rut}</td>
            <td>{item.razonSocial}</td>
            <td>{item.ciudad}</td>
            <td>{item.direccion}</td>
            <td hidden>{item.creado_por}</td>


            {/* <td align="center"><Link data-bs-toggle="tooltip" title="Editar" to={`/editaEmpresa/${item.id}`} ><h3><FcEditImage/></h3></Link></td>
                        <td align="center" >
                    <Link data-bs-toggle="tooltip" title="Eliminar" to="" onClick={(e)=>eliminarEmpresa(e,item.id)} ><h3><FcDeleteRow/></h3></Link>
                   </td> */}

            <td align="center">
              <Dropdown><Dropdown.Toggle variant="light">
                <FcMenu />
              </Dropdown.Toggle> <Dropdown.Menu>
                  <Dropdown.Item href={`/editaEmpresa/${item.id}`}><FcEditImage />Editar </Dropdown.Item>


                  <Dropdown.Item onClick={() => setSmShow(true)} className="me-2" > <FcDeleteRow />Eliminar</Dropdown.Item>

                </Dropdown.Menu></Dropdown>

            </td>

            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}
              aria-labelledby="example-modal-sizes-title-sm">
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Eliminar Empresa
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Desea Eliminar la Empresa  {item.nombreEmpresa}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={(e) => eliminarEmpresa(e, item.id)}>
                  <FcDeleteRow />Eliminar
                </Button>
              </Modal.Footer>
            </Modal>



          </tr>
        )
      });
  }

  return (
    <body>

      <nav className="navbar navbar-light" >
        <div className="container-fluid">
          <Link data-bs-toggle="tooltip" title="Crear Nueva Empresa" className="navbar-brand"
            to="/createEmpresa"><h3><FcAddDatabase /></h3> </Link>

          <div>
            <Button className="btn btn-secondary btn-sm">Empresas Regitradas: {contarEmpresa} </Button>
          </div>

          <div className="d-flex">
            <input type="text" className="form-control" id="pwd" value={busqueda}
              placeholder="Buscar por EMPRESA, RUT" onChange={handleChange} />

          </div>
        </div>
      </nav>


      <div className="container mt-12">
        <Table striped bordered hover>
          <thead className='table-dark' >
            <tr align="center">
              {/* https://www.youtube.com/watch?v=HPMSBCfdKKU */}
              <th hidden>N°</th>
              <th>Nombre</th>
              <th>Rut</th>
              <th>Razon Social</th>
              <th>Ciudad</th>
              <th>Direccion</th>
              <th hidden >creado por</th>

              <th> <GoKebabHorizontal /></th>
            </tr>
          </thead>



          <tbody>
            {listaUsuarios_HTMLTABLE}
          </tbody>
        </Table>

        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      </div>

    </body>
  );
}