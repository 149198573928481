import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
//import swal from 'sweetalert';

import { FcCalendar } from "react-icons/fc";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ActualizarUf from "./actualizaUf";



export default function ListUf(){
    const {http}=AuthUser();
    const [loading,setLoading]=useState(true);
    const [userProfile,setUserProfile]=useState([]);
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    useEffect(()=>{
        fetchUserProfile();
    },[]);

    const fetchUserProfile=async()=>{
     await http.get('/listUf').then((res)=>{  
          //  console.log(res.data);        
           if(res.status===200)
           {
              setUserProfile(res.data.uf);
            
           }
           setLoading(false);
        });
        };

      
        

        var listaUf_HTMLTABLE="";
    if(loading){
        return  <div className="center">        
        <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos..
      </button></div>
    }else{
     
      listaUf_HTMLTABLE=
            userProfile.map((item)=>{
                return(  


                    <p key={item.id} >

                    <button type="button" className="btn btn-secondary btn-sm">
                        UF $ {item.uf_UltimoDiaMes} / Fecha {item.fecha_facturacion}                   
                         </button>
                   
                    <button type="button" className="btn btn-sm "><Link title="Actualizar UF" to={`/actualizarUf/${item.id}`} >
                           <h3><FcCalendar/></h3></Link></button>

                            {/* <button type="button" class="btn btn-sm" onClick={handleShow}> <h3><FcCalendar/></h3>
                            <Link title="Actualizar UF" to={`/actualizarUf/${item.id}`} ></Link>
                           </button> */}

                           
                  </p>




                
                )            
            });      
    }



    return(
<body>

<br></br>
 <div className="container mt-6">   
    
  <table className="blueTable">
  
          <thead className="table-dark">

          <Modal show={show} onHide={handleClose} >
<Modal.Header closeButton>
  <Modal.Title>Modal heading</Modal.Title>
</Modal.Header>
<Modal.Body>
  
 
{/* <ActualizarUf /> */}
  
  
  </Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button>
</Modal.Footer>
</Modal>
    </thead>
    


    <tbody>
    {listaUf_HTMLTABLE}
    </tbody>
  </table>
</div>

</body>
    );
}