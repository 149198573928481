import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";


export default function CreateEmpresa(){  

  const navigate=useNavigate();
    const {http}=AuthUser();
    const [registerInput, setRegister] = useState({
      id:'',     
      nombreEmpresa: '',
      rut: '',
      razonSocial:'',
      ciudads_id: '',
      direccion: '',
   //   users_id: '', //id usuario a quien se le asignara la empresa
    name:'',// nombre usuario a quien se le asignara la empresa
   //   creado_por: '',
   
      error_list: [],
    });

    const [loading,setLoading]=useState(true);
    const [listaCiudades,setListaCiudades]=useState([]);
    const [listaUsuario,setListaUsuarios]=useState([]);
    const [user,setUser]=useState([]);

    useEffect(()=>{  
      http.get(`/listaCiudades`).then(res=>{            
          if(res.status===200){
            setListaCiudades(res.data.ciudades)
          }setLoading(false); }); },[]);

          useEffect(()=>{  
            http.get(`/listUsers`).then(res=>{            
                if(res.status===200){
                  setListaUsuarios(res.data.users)
                }setLoading(false); }); },[]);

                useEffect(()=>{  
                  http.get(`/user`).then(res=>{                            
                      if(res.status===200){
                        setUser(res.data.user)
                      }setLoading(false); }); },[]);

    const handleInput = (e) => {
      e.persist();
      setRegister({ ...registerInput, [e.target.name]: e.target.value });
    };

    const registerSubmit = (e) => {
      e.preventDefault();
        const data = {
        nombreEmpresa: registerInput.nombreEmpresa,
        rut: registerInput.rut,
        razonSocial:registerInput.razonSocial,
        ciudads_id: registerInput.ciudads_id,
        direccion: registerInput.direccion,
        //users_id: registerInput.users_id, //id usuario a quien se le asignara la empresa
       // name:registerInput.nombre_emp,// nombre usuario a quien se le asignara la empresa
        creado_por:registerInput.creado_por,
        
      };   
       http.post('/createEmpresa',data).then((res)=>{
     console.log(res.data);
     if(res.data.status===200)
     {   
       swal("Success",res.data.message,"success");
        navigate('/listaEmpresas')
           }else{
            setRegister({...registerInput,error_list: res.data.validate_errors})
           }
     
    
       });
       if(loading)
       {
        <h4>Trayendo ciudad...</h4>
      }
    }
    return(
    

      <body>

      <div className="container mt-4">
        <div className="card bg-secondary text-black">
          <form onSubmit={registerSubmit}>
            <div className="card-body">
               {/* <!-- Multiple inputs 1 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                 <input type="text" className="form-control" placeholder="Nombre Empresa" name="nombreEmpresa" 
     onChange={handleInput} value={registerInput.nombreEmpresa}/> 
     <label className="form-label">Nombre Empresa:</label>
     <span className="badge bg-danger">{registerInput.error_list.nombreEmpresa}</span>    
                  </div>

                </div>

                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Rut Empresa" name="rut"
       onChange={handleInput} value={registerInput.rut}/>  
    <label className="form-label">Rut Empresa:</label>
    <span className="badge bg-danger">{registerInput.error_list.rut}</span>     
                  </div>


                </div>               
               </div>
            </div>


  {/* <!-- Multiple inputs 2 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                 <input type="text" className="form-control" placeholder="Razon Social" name="razonSocial" 
     onChange={handleInput} value={registerInput.razonSocial}/> 
     <label className="form-label">Razon Social:</label>
     <span className="badge bg-danger">{registerInput.error_list.razonSocial}</span> 
                  </div>
                </div>

                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                  <select className="form-control"  name="ciudads_id" placeholder="Ciudad" onChange={handleInput}>
 <option defaultValue disable>Elija una Ciudad..</option>
{listaCiudades.map((items) => (<option key={items.id} value={items.id}>{items.ciudad}</option>))}
    </select>
        <label className="form-label">Ciudad:</label>
    <span className="badge bg-danger">{registerInput.error_list.ciudads_id}</span>  
                  </div>


                </div>               
               </div>
            </div>


  {/* <!-- Multiple inputs 3 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">

                  <div className="form-floating mb-3 mt-3">
                   <input type="text" className="form-control" placeholder="Dirección" name="direccion"
     onChange={handleInput} value={registerInput.direccion}/> 
    <label className="form-label">Dirección:</label>
    <span className="badge bg-danger">{registerInput.error_list.direccion}</span>   
                  </div>
                </div>

              
            </div>




              <div className="d-flex">
                <div className="btn-group">
                  <button type="button" className="btn"></button>
                  <button type="submit" data-bs-toggle="tooltip" title="Ingresar Empresa" className="btn btn-outline-secondary"><h3><FcAcceptDatabase /></h3></button>
                  <button type="button" className="btn"></button>
                  <button type="submit" className="btn btn-outline-secondary">
                    <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listaEmpresas"><h3><FcDeleteDatabase /></h3></Link></button>
                  <button type="button" className="btn"></button>

                </div>
              </div>

   </div>
            </div>
          </form>
        </div>
      </div>
      
    </body>


      /*{++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++}*/
      
       
    )
}