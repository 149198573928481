import { Routes, Route} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "../components/home";
import Dashboard from '../components/dashboard';
import ListFlota from '../components/ListFlota';
import CreateFlota from '../components/createFlota';
import EditFlota from '../components/editFlota';
import Register from '../components/register';
import AuthUser from '../components/authUser';
import Usuario from '../components/usuario';
import CambioPass from '../components/cambioPass';
import ListUser from '../components/listUsers';
import EditUser from '../components/editUser';
import ListEmpresa from '../components/listEmpresa';
import CreateEmpresa from '../components/createEmpresa';
import EditEmpresa from '../components/editEmpresa';
import Login from '../components/login';
import CreateContrato from '../components/createContrato';
import ListContrato from '../components/listContrato';
import EditContrato from '../components/editContrato';
import ListUf from '../components/listUf';
import ActualizarUf from '../components/actualizaUf';
import IngresoTaller from '../components/ingresoTaller';
import ListUnidadTaller from '../components/listUnidadTaller';
import ExportarExcelFlota from '../component/ModalExcel';
import { BiPowerOff } from "react-icons/bi";
import { FcDataConfiguration } from "react-icons/fc";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdPassword } from "react-icons/md";



import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';





function Auth() {
  const { token, logout } = AuthUser();


  const logoutUser = () => {
    if (token !== undefined) {
      logout();

    }
  }

  return (
    <div >
      <Navbar expand="lg"  >
        <Container >
       
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav 
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '120px' }}
              navbarScroll
            >
              <Nav.Link href="/" active>Home</Nav.Link>
              <Nav.Link href="/dashboard" active>Dashboard</Nav.Link>

              <NavDropdown title="Mantenedor" id="navbarScrollingDropdown" active>
                <NavDropdown.Item  href="/listaEmpresas">Empresas</NavDropdown.Item>
                <NavDropdown.Item href="/listContrato">Contratos</NavDropdown.Item>
                <NavDropdown.Item href="/listFlota"> Flota</NavDropdown.Item>
                <NavDropdown.Item href="/listUnidadesTaller">Unidades en taller</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                </NavDropdown.Item>
              </NavDropdown>

            </Nav>


            <div className="d-flex" active>

              <NavDropdown title={<FcDataConfiguration />} id="navbarScrollingDropdown" active>
              <NavDropdown.Item href="/listUsers" active><AiOutlineUserAdd /> Nuevo Usuario</NavDropdown.Item>
                <NavDropdown.Item href="/cambioPass"><MdPassword /> Cambio PassWord</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUser}> <BiPowerOff /> Salir </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item >  &nbsp;   &nbsp;   &nbsp;</NavDropdown.Item>
              <NavDropdown.Item > <Usuario /> </NavDropdown.Item>

                       </div>

          </Navbar.Collapse>
        </Container>
      </Navbar>

    
<div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/listFlota" element={<ListFlota />} />
          <Route path="/createFlota" element={<CreateFlota />} />
          <Route path="/editFlota/:id" element={<EditFlota />} />

          <Route path="/register" element={<Register />} />
          <Route path="/cambioPass" element={<CambioPass />} />
          <Route path="/listUsers" element={<ListUser />} />
          <Route path="/editaUsuarios/:id" element={<EditUser />} />

          <Route path="/listaEmpresas" element={<ListEmpresa />} />
          <Route path="/createEmpresa" element={<CreateEmpresa />} />
          <Route path="/editaEmpresa/:id" element={<EditEmpresa />} />

          <Route path="/createContrato" element={<CreateContrato />} />
          <Route path="/listContrato" element={<ListContrato />} />
          <Route path="/editContrato/:id" element={<EditContrato />} />
          <Route path="/listUf" element={<ListUf />} />
          <Route path="/actualizarUf/:id" element={<ActualizarUf />} />

          <Route path="/ingresoTaller/:id" element={<IngresoTaller />} />
          <Route path="/listUnidadesTaller" element={<ListUnidadTaller />} />

          <Route path="/exportarFlotaExcel/:id" element={<ExportarExcelFlota />} /> 


          exportarFlotaExcel

        </Routes>
      </div> 
     

    </div>

  )


}

export default Auth;