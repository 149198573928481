import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import AuthUser from "./authUser";
//import swal from 'sweetalert';
import { FcAddDatabase } from "react-icons/fc";
import Pagination from "../component/Paginacion";
import Table from 'react-bootstrap/Table';


export default function ListUnidadTaller() {

 // const [show, setShow] = useState(false);

  // +++++++++++++++++++++++++++++++++ paginacion +++++++++++++++++++++++
 // const [posts, setPosts] = useState([])
  const [loadings, setLoadings] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(15) //7 Per Page
  //+++++++++++++++++++++++++++++++++++++




  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [flotaProfile, setFlotaProfile] = useState([]);
  const [tablaFlota, setTablaFlota] = useState([]); //Lista los datos de manera estatica
  const [busqueda, setBusqueda] = useState("");
  useEffect(() => {
    fetchFlotaProfile();
  }, []);
  const fetchFlotaProfile = async () => {
    await http.get('/listUnidadesTaller').then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setFlotaProfile(res.data.unidadTaller);
        setTablaFlota(res.data.unidadTaller);
        setLoadings(false);


      }
      setLoading(false);

    });

  };


 const handleChange = e => {
    setBusqueda(e.target.value);
    filtrar(e.target.value)
  }
  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaFlota.filter((elementos) => {
      if (elementos.ppu.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos;

      }
        else if (elementos.estad.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return elementos; }

        //     }else if ( elementos.observacion.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())){
        //       return elementos; }               
     
      return false;
    });
    setFlotaProfile(resultadosBusqueda);
  }


  if (loadings && flotaProfile.length === 0) {
    return <h2>Loading...</h2>
  }
  //Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = flotaProfile.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math.ceil(flotaProfile.length / postsPerPage)




  var listaTaller_HTMLTABLE="";
    if (loading) {
        return  <div className="center">        
        <button className="btn btn-primary" disabled>
        <span className="spinner-border spinner-border-sm"></span>
        Trayendo Datos.. </button></div>
    }else{
        listaTaller_HTMLTABLE=
        currentPosts.map((item) => {
          return (
      
            <tr key={item.id} >
              
                <td >{item.idt}</td>
                <td hidden >{item.flotas_id}</td>
                <td >{item.ppu}</td>
                <td >{item.f_ingreso_taller}</td>
                <td>{item.f_salida_taller}</td>

                <td >{item.dias_taller}</td>
                <td >{item.dias_mes}</td>
                <td >{item.total_dias}</td>
                <td>{item.total_mes}</td>

                <td hidden>{item.estados_id}</td>
                <td>{item.estad}</td>
                <td>{item.creado_por}</td>
                <td >{item.observacion}</td>
            </tr>
            )               
        });      
}

return(
    <body>
  <nav className="navbar navbar-light" >
        <div className="container-fluid">
          <Link data-bs-toggle="tooltip" title="Ingresar Nueva Patente" className="navbar-brand"
            to="/createFlota"><h3><FcAddDatabase /></h3> </Link>

        
          <div className="d-flex">
            <input type="text" className="form-control" id="pwd" value={busqueda}
              placeholder="Buscar x Patente o Taller "onChange={handleChange}  />

          </div>
        </div>
      </nav>



      <div className="container mt-12">


        <Table striped bordered hover>


          <thead className='table-dark' >


  <tr>
  {/* https://www.youtube.com/watch?v=HPMSBCfdKKU */}
  <th>Id</th>
  <th>Patente</th>         
  <th>Ing.taller</th> 
  <th>Sal.taller</th>
   <th>Dias en taller</th>
  <th>Dias del mes</th> 
  <th>Total Dias</th>
  <th>Total mes</th>
  <th>Estado</th>
  <th>Creado por</th>
  <th>obs</th>
 
 
  
    
  <th colSpan="3" align="center" hidden>Opciones</th>         
  </tr>
</thead>



<tbody>
{listaTaller_HTMLTABLE}
</tbody>
</Table>
<Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
</div>

</body>
);
}

