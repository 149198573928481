import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AuthUser from "../components/authUser";
import { CSVLink } from 'react-csv';



export default function ExportarExcelFlota() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [flotaProfile, setFlotaExcel] = useState([]);
  const [listaNumeroContrato, setListaNumeroContrato] = useState([]);
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    http.get(`/uFListaFlotaContrato`).then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setListaNumeroContrato(res.data.NumeroContrato);
      }
      setLoadings(false);
    });
  }, []);

  useEffect(() => {
    //Ruta que trae los datos para editar con el id
    http.get(`/exportarFlotaExcel/${id}`).then((res) => {
      console.log(res.data);

      if (res.data.status === 200) {
        setFlotaExcel(res.data.flotaExcel);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listFlota");
      }
      setLoadings(false);
    });
  }, [id, navigate]);

  var listaFlotas_HTMLTABLE = "";
  if (loadings) {
    return (
      <div className="center">
        <button className="btn btn-primary" disabled>
          <span className="spinner-border spinner-border-sm"></span>
          Trayendo Datos..
        </button>
      </div>
    );
  } else {


    listaFlotas_HTMLTABLE = flotaProfile.map((item) => {
      return (
        <tr key={item.id}>
          <td hidden>{item.id}</td>
          {/* <td>{item.idcon}</td> */}
          <td hidden>{item.numContrato}</td>
          <td>{item.ciudad}</td>
          <td>{item.patente}</td>
          <td>{item.tipo}</td>
          <td>{item.marca}</td>
          <td>{item.modelo}</td>
          <td>{item.año}</td>
          <td>{item.fecha_entrega}</td>
          <td data-bs-toggle="tooltip" title="Meses que dura el contrato" >{item.meses_contrato}</td>
          <td data-bs-toggle="tooltip" title="UF mensual del Arriendo la unidad"> UF {item.uf_mes}</td>
          <td>{item.dias_mes - item.dias_taller}</td>
          <td>{item.dias_taller}</td>
          <td data-bs-toggle="tooltip" title="UF del mes ">$ {item.uf_UltimoDiaMes}</td>
          <td>{item.fecha_facturacion}</td>
          <td>$ {item.total_pagar}</td>
          <td>{item.estado}</td>
          <td data-bs-toggle="tooltip" title={item.observacion}><textarea readOnly>{item.observacion}</textarea></td>
        </tr>
      );
    });
  }

  return (
    <>
      <CSVLink data={flotaProfile} filename={`Flota Contrato_${id}`} className="btn btn-success"> Excel</CSVLink>
      <body>
        <div className="container mt-6">
          <table className="blueTable">
            <thead>
              <tr>
                {/* <th>idcon</th> */}
                <th hidden>N°Contrato</th>
                <th>Ciudad</th>
                <th>Patente</th>
                <th>Tipo</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Año</th>
                <th>F Entrega</th>
                <th>M Contrato</th>
                <th>Uf Arriendo</th>
                <th>Dias Mes</th>
                <th>Dias Taller</th>
                <th>Mes UF</th>
                <th>F Facturacion</th>
                <th>Total</th>
                <th>Estado</th>
                <th>Obs</th>
              </tr>
            </thead>
            <tbody>{listaFlotas_HTMLTABLE}</tbody>
          </table>
        </div>
      </body>
      ...
    </>
  );
}
