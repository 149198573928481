import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";



export default function EditEmpresa() {
  const { http } = AuthUser();
  const navigate = useNavigate();
  const { id } = useParams();

  const [trayendo, setTrayendo] = useState([true]);
  const [registerInput, setRegister] = useState({
    id: null,
    nombreEmpresa: '', rut: '', razonSocial: '', ciudads_id: '', direccion: '', creado_por: '',
    error_list: [],
  });
  const [error, setError] = useState([]);

  const [loading, setLoading] = useState(true);
  const [listaCiudades, setListaCiudades] = useState([]);



  useEffect(() => {
    http.get(`/listaCiudades`).then(res => {
      if (res.status === 200) {
        setListaCiudades(res.data.ciudades)
      }
      setLoading(false);
    });
  }, []);


  useEffect(() => {
    //Ruta que trae los datos para editar con el id
    http.get(`/editaEmpresa/${id}`).then((res) => {
      if (res.data.status === 200) {
        setRegister(res.data.empresas);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listaEmpresas");

      }
      setTrayendo(false);
    }

    );

  }, [id, navigate]);



  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  }

  const editEmpresa = (e) => {
    e.preventDefault();

    // const {id} = par();
    const data = {
      nombreEmpresa: registerInput.nombreEmpresa,
      rut: registerInput.rut,
      razonSocial: registerInput.razonSocial,
      ciudads_id: registerInput.ciudads_id,
      direccion: registerInput.direccion,
      //users_id: registerInput.users_id, //id usuario a quien se le asignara la empresa
      // name:registerInput.nombre_emp,// nombre usuario a quien se le asignara la empresa
      creado_por: registerInput.creado_por,
    };
    //Ruta para cambiar los datos con el id.
    http.put(`/editarEmpresa/${id}`, data).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/listaEmpresas');
        // setError([]);
      }
      else if (res.data.status === 422) {
        swal("Datos deben estar completos", "", "error");
        setError(res.data.errors);
      }
      else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate('/listaEmpresas');
      }

    });
  }
  if (trayendo) {
    <h4>Trayendo datos para Editar...</h4>
  }

  if (loading) {
    <h4>Trayendo ciudad...</h4>
  }
  return (
    <body>

      <div className="container mt-4">
        <div className="card bg-secondary text-black">
          <form onSubmit={editEmpresa}>
            <div className="card-body">
              {/* <!-- Multiple inputs 1 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" name="nombreEmpresa" onChange={handleInput}
                        value={registerInput.nombreEmpresa} className="form-control" />
                      <label className="form-label">Nombre Empresa:</label>
                      <small className="text-danger">{error.nombreEmpresa}</small>
                    </div>

                  </div>

                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" name="rut" onChange={handleInput}
                        value={registerInput.rut} className="form-control" required/>
                      <label className="form-label">Rut Empresa:</label>
                      <small className="text-danger">{error.rut}</small>
                    </div>


                  </div>
                </div>
              </div>


              {/* <!-- Multiple inputs 2 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" name="razonSocial" onChange={handleInput}
                        value={registerInput.razonSocial} className="form-control" />
                      <label className="form-label">Razon Social:</label>
                      <small className="text-danger">{error.razonSocial}</small>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <select name="ciudads_id" className="form-control" onChange={handleInput}>
                        <option value={registerInput.ciudads_id} >{registerInput.ciudads_id}</option>
                        {listaCiudades.map((items) => (<option key={items.id} value={items.id}>{items.ciudad}</option>))}
                      </select>
                      <label className="form-label">Ciudad:</label>
                      <small className="text-danger">{error.ciudads_id}</small>
                    </div>


                  </div>
                </div>
              </div>


              {/* <!-- Multiple inputs 3 --> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col">

                    <div className="form-floating mb-3 mt-3">
                      <input type="text" name="direccion" onChange={handleInput}
                        value={registerInput.direccion} className="form-control" />
                      <label className="form-label">Dirección:</label>
                      <small className="text-danger">{error.direccion}</small>
                    </div>
                  </div>


                </div>




                <div className="d-flex">
                  <div className="btn-group">
                    <button type="button" className="btn"></button>
                    <button type="submit" data-bs-toggle="tooltip" title="Editar Empresa" className="btn btn-outline-secondary"><h3><FcAcceptDatabase /></h3></button>
                    <button type="button" className="btn"></button>
                    <button type="submit" className="btn btn-outline-secondary">
                      <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listaEmpresas"><h3><FcDeleteDatabase /></h3></Link></button>
                    <button type="button" className="btn"></button>

                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>

    </body>



  );
}
