import { useState } from "react"
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";


export default function CambioPass(){
  const navigate=useNavigate();
    const {http}=AuthUser();
    const [registerInput, setRegister] = useState({       
      password: '',
      newPassword: '',
      error_list: [],     
    });
    const handleInput = (e) => {
      e.persist();
      setRegister({ ...registerInput, [e.target.name]: e.target.value });
    };

    const registerSubmit = (e) => {
      e.preventDefault();
  
      const data = {       
        password: registerInput.password,
        newPassword: registerInput.newPassword,       
      };
     
       http.post('/cambioPass',data).then((res)=> {    
       // console.log(res.data); 
       if(res.data.status===200)
       {     
    // setToken(res.data.user,res.data.access_token);
         swal("Cambio Password exitoso!!",res.data.message,"success");
         navigate('/dashboard')
         
             }   
             else if (res.data.status === 401)
             {     
                 swal("Sus credenciales no son correctas!!",res.data.message,"warning");
             }
              else {
                setRegister({ ...registerInput, error_list: res.data.validation_errors });
             }   
            
        }
        
        )
    }
    return(
        <div className="row justify-content-center pt-5">
            <div className="col-sm-6">
                <div className="card p-4">

                <form onSubmit={registerSubmit}>

                <div className="form-floating mb-3 mt-3">
   
    <input type="text" className="form-control" id="uname" placeholder="Enter username" name="password"     
       value={registerInput.password} onChange={handleInput} />
     <label className="form-label">Password Actual:</label>
   <span className="badge bg-danger">{registerInput.error_list.password}</span>
  </div> 


  <div className="form-floating mt-3 mb-3">    
    <input type="password" className="form-control" id="pwd" placeholder="Enter password" name="newPassword"    
     value={registerInput.newPassword}  onChange={handleInput} />
     <label className="form-label">Ingrese nuevo Password:</label>
    <span className="badge bg-danger">{registerInput.error_list.newPassword}</span>
  </div>
 
  <button type="submit" className="btn btn-primary">Ingresar
  </button>

</form>
                </div>
                

            </div>
        </div>
    )
}