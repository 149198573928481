import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";


export default function EditUser(){
   const {http}=AuthUser();
  const navigate=useNavigate();
  const {id}=useParams()
  const [trayendo,setTrayendo]=useState(true);
 // const [loading,setLoading]=useState(true);
  const [editInput,setEditInput]=useState({id:null,name:'',email:'',role:'',error_list:[]});

  const [error, setError] = useState([]);

   
      useEffect(() => {  
      //trae los datos del usuario a editar 
       http.get(`/editaUsuarios/${id}`).then((res)=>{
    // console.log(res.data.user);
     if(res.data.status===200){
      setEditInput(res.data.user);
     }else if(res.data.status === 404){
      swal("Error", res.data.message, "error");
      navigate('/listUsers');
     }
     setTrayendo(false);      
       }
       );
    },[id,navigate]);

    const handleInput = (e) => {
      e.persist();
      setEditInput({...editInput, [e.target.name]: e.target.value });
    }
  
    const editUsuario =(e) =>{
      e.preventDefault();
  
     // const {id} = par();
     const data = {
      id:editInput.id,
      name: editInput.name,          
      email: editInput.email,
      role:editInput.role,
     
    };
      //Ruta para cambiar los datos con el id.
      http.put(`/editarUsuarios/${id}`,data).then((res) => {
        if(res.data.status===200)
        {
          swal("Success",res.data.message,"success");
          navigate('/listUsers');
         // setError([]);
        }
        else if(res.data.status===422)
        {
          swal("Datos deben estar completos","","error");
          setError(res.data.errors);
        }
        else if(res.data.status===404)
        {
          swal("Error",res.data.message,"error");
          navigate('/listUsers');
        }
  
      });
    }
    if(trayendo)
    {
      <h4>Trayendo datos para Editar...</h4>
    }
   
  // if(loading)
  // {
  //   <h4>Trayendo ciudad...</h4>
  // }

    return(
        <div className="row justify-content-center pt-5">
         
            <div className="col-sm-6">
                <div><Link className="nav-link" to="/listUsers"><h3><BsFillArrowLeftSquareFill /></h3></Link></div>
                <div className="card p-4">
                 
                <form onSubmit={editUsuario}>
    <div className="form-floating mt-3 mb-3">     
    <input type="text" className="form-control" id="1"  name="name" placeholder="Nombre y Apellido:"
   onChange={handleInput} value={editInput.name} />   
    <label className="form-label">Nombre y Apellido:</label>
    <span className="badge bg-danger">{error.name}</span>
  </div>

  <div className="form-floating mt-3 mb-3">    
    <input type="email" className="form-control" id="2"  name="email" placeholder="Email"
    onChange={handleInput} value={editInput.email} />
    <label className="form-label">Email:</label>
    <span className="badge bg-danger">{error.email}</span>
    </div>

    <div className="form-floating mt-3 mb-3">    
    <input type="number" className="form-control" id="3"  name="role" placeholder="Role"
    onChange={handleInput} value={editInput.role} />
    <label className="form-label">Role (1=Admin | 2=Usuario):</label>
    <span className="badge bg-danger">{error.role}</span>
   
  </div>
   
  <div class="d-grid gap-3">
  <button type="submit" className="btn btn-primary btn-block">Guardar</button>
</div>

</form>
                </div>

            </div>
        </div>
    )
}
