import { useEffect, useState } from "react";
import AuthUser from "./authUser";

export default function Dashboard(){
    const {http}=AuthUser();
    const [userProfile,setUserProfile]=useState('');
    useEffect(()=>{
        fetchUserProfile();
    },[]);

    const fetchUserProfile=()=>{
        http.post('/userProfile').then((res)=>{
            setUserProfile(res.data);
        });
        }

    function renderElement(){    
    if(userProfile){
        return <div>
             Pagina Dashboard
            <h4>Hola {userProfile.name}</h4>
        </div>
    }else{
        return(
       <div className="center">        
            <button className="btn btn-primary" disabled>
            <span className="spinner-border spinner-border-sm"></span>
            Trayendo Datos..
          </button></div>
        )
    }
}
    return(
        <div>
            {renderElement()}            
            </div>
    )
}