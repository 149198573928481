import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";



export default function Register() {


  const [cPassword, setCPassword] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState('form-control');
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (registerInput.password === cPassword) {
        setShowErrorMessage(false);
        setCPasswordClass('form-control is-valid')
      } else {
        setShowErrorMessage(true)
        setCPasswordClass('form-control is-invalid')
      }
    }
  }, [cPassword]);

  const handleCPassword = (e) => {
    setCPassword(e.target.value);
    setIsCPasswordDirty(true);
  }

  const navigate = useNavigate();
  const { http } = AuthUser();
  const [registerInput, setRegister] = useState({
    name: '',
    email: '',
    password: '',
    cargo: '',
    role: '',
    error_list: [],
  });

  const [error, setError] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: registerInput.name,
      email: registerInput.email,
      password: registerInput.password,
      cargo: registerInput.cargo,
      role: registerInput.role,
    };
    http.post('/register', data).then((res) => {
      // console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/register')
      } else if (res.data.status === 402) {
        swal("Usuario no autorizado para crear nuevos usuarios", "", "error");
        setError(res.data.errors);
      } else {
        setRegister({ ...registerInput, error_list: res.data.validation_errors })

      }


    });
  }

  return (

    <body>

      <div className="container mt-4">
        <div className="card bg-secondary text-black">
          <form onSubmit={registerSubmit}>
            <div className="card-body">
               {/* <!-- Multiple inputs 1 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                   <input type="text" className="form-control" id="1" name="name" placeholder="Nombre y Apellido"
                  onChange={handleInput} value={registerInput.name} />
                <label className="form-label">Nombre y Apellido:</label>
                <span className="badge bg-danger">{registerInput.error_list.name}</span>
                  </div>

                </div>

                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                    <input type="email" className="form-control" id="2" name="email" placeholder="Email"
                  onChange={handleInput} value={registerInput.email} />
                <label className="form-label">Email:</label>
                <span className="badge bg-danger">{registerInput.error_list.email}</span>
                  </div>


                </div>               
               </div>
            </div>


  {/* <!-- Multiple inputs 2 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="4" name="role" min={1} max={2} placeholder="Role"
                  onChange={handleInput} value={registerInput.role} />
                <label className="form-label">Role (1=Admin | 2=Usuario):</label>
                <span className="badge bg-danger">{registerInput.error_list.role}</span>
                  </div>
                </div>

                <div className="col">
                  <div className="form-floating mb-3 mt-3">
                    <input type="number" className="form-control" id="7" name="cargo" min={1} max={2} placeholder="Cargo"
                  onChange={handleInput} value={registerInput.cargo} />
                <label className="form-label">Cargo (1=Ejecutivo | 2=Coordinador):</label>
                <span className="badge bg-danger">{registerInput.error_list.cargo}</span>
                  </div>


                </div>               
               </div>
            </div>


  {/* <!-- Multiple inputs 3 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">

                  <div className="form-floating mb-3 mt-3">
                    <input type="password" className="form-control" id="password" name="password" placeholder="Password"
                  onChange={handleInput} value={registerInput.password} />
                <label className="form-label">Password (min 8 Caracteres):</label>
                <span className="badge bg-danger">{registerInput.error_list.password}</span>
                  </div>
                </div>

                <div className="col">
                  <div className="form-floating mb-2 mt-3">
                     <input type="password" className={cPasswordClass} id="confirmPassword" value={cPassword} placeholder="Confirme Password"
                  onChange={handleCPassword} />
                <label className="form-label">Confirme Password:</label>
                {showErrorMessage && isCPasswordDirty ? <div> Ingrese password correcto </div> : ''}
                  </div>


                </div>               
               </div>
            </div>




              <div className="d-flex">
                <div className="btn-group">
                  <button type="button" className="btn"></button>
                  <button type="submit" data-bs-toggle="tooltip" title="Ingresar Usuario" className="btn btn-outline-secondary"><h3><FcAcceptDatabase /></h3></button>
                  <button type="button" className="btn"></button>
                  <button type="submit" className="btn btn-outline-secondary">
                    <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listUsers"><h3><FcDeleteDatabase /></h3></Link></button>
                  <button type="button" className="btn"></button>

                </div>
              </div>


            </div>
          </form>
        </div>
      </div>
    </body>



  )
}