import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthUser from "./authUser";
import swal from 'sweetalert';
import { FcAcceptDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";
import ListFlota from "./ListFlota";


export default function IngresoTaller() {

  const navigate = useNavigate();
  const { id } = useParams();
  const { http } = AuthUser();
  const [trayendo,setTrayendo]=useState([true]);
  const [registerInput, setRegister] = useState(
    {
      id:'',
      //idf: '',
      flotas_id:'',    
      patente: '',     
      f_ingreso_taller:'',
      f_salida_taller:'',
      dias_taller:'',
      dias_mes:'',
      total_dias:'',
      total_mes:'',
      estados_id:'',
      observacion: '',
      error_list: [],
    });

    const [error, setError] = useState([]);

  const [loading, setLoading] = useState(true);
  const [listaCiudades, setListaCiudades] = useState([]);
  const [listaFlota, setListaFlota] = useState([]);
  const [listaMarcas, setListaMarcas] = useState([]);
  const [listaContrato,setListaContrato]=useState([]);
  const [listaEstados, setListaEstados] = useState([]);

  useEffect(() => {
    http.get(`/listaCiudades`).then(res => {
      if (res.status === 200) {
        setListaCiudades(res.data.ciudades)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listFlota`).then(res => {
      if (res.status === 200) {
        setListaFlota(res.data.flota)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listaEstados`).then(res => {
      if (res.status === 200) {
        setListaEstados(res.data.estado)
      } setLoading(false);
    });
  }, []);

  useEffect(() => {
    http.get(`/listaMarcas`).then(res => {
      if (res.status === 200) {
        setListaMarcas(res.data.marcas)
      } setLoading(false);
    });
  }, []);




  useEffect(() => {      
    //Ruta que trae los datos para editar con el id
    http.get(`/editFlota/${id}`).then((res) => {
      console.log(res.data);    
      if (res.data.status === 200) {
        setRegister(res.data.flota);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        navigate("/listFlota");
        
      }
      setTrayendo(false);
    }   
    
    );
    
  },[id,navigate]);

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
     id: registerInput.id,
      flotas_id:registerInput.flotas_id,       
      f_ingreso_taller:registerInput.f_ingreso_taller,
      f_salida_taller:registerInput.f_salida_taller,
      estados_id:registerInput.estados_id,
      observacion: registerInput.observacion,
      //creado_por:registerInput.creado_por,

    };
   
    http.post('/createUnidadesTaller', data).then((res) => {
      console.log(res.data);
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        navigate('/listFlota')
       // setError(res.data.errors);
      } else if(res.data.status===422)
      {
        swal("Confirme datos","","error");
        setError(res.data.errors);
      }


    });
}
if(trayendo)
{
  <h4>Trayendo datos para Editar...</h4>
}

if(loading)
{
<h4>Trayendo ciudad...</h4>
  }
  return (

    <body>

      <div className="container mt-4">
      <div className="card bg-secondary text-black">
        <form onSubmit={registerSubmit}>
          <div className="card-body">
            {/* <h2>Ingresar Nueva Patente al Contrato</h2> */}
           
            {/* <!-- Multiple inputs 1 --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select disabled className="form-control" name="contratos_id" placeholder="Numero Contrato" id="contratos_id" onChange={handleInput}>
                    <option value={registerInput.contratos_id}>{registerInput.contratos_id}</option>
                    {listaContrato.map((items) => (<option key={items.id} value={items.idc}>{items.idc}-{items.numContrato}-{items.nomEmp}</option>))}
                    </select>
                    <label className="form-label">Elija Numero de Contrato:</label>
                    <small className="badge bg-danger">{error.idf}</small> 
                  </div>
                </div>



                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select disabled className="form-control" name="ciudads_id" id="ciudads_id" placeholder="Ciudad" onChange={handleInput}>
                    <option value={registerInput.ciudads_id}>{registerInput.ciudads_id}</option>
                      {listaCiudades.map((items) => (<option key={items.id} value={items.id}>{items.ciudad}</option>))}
                    </select>
                    <label className="form-label">Ciudad:</label>
                    <small className="badge bg-danger">{error.ciudads_id}</small> 
                  </div>
                </div>


                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <input disabled type="text" className="form-control" placeholder="Patente" name="patente" id="patente"
                      onChange={handleInput} value={registerInput.patente} />
                    <label className="form-label">Patente:</label>
                    <small className="badge bg-danger">{error.patente}</small> 
                  </div>

                </div>
                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input disabled type="text" className="form-control" placeholder="Tipo" name="tipo" id="tipo"
                      onChange={handleInput} value={registerInput.tipo} />
                    <label className="form-label">Tipo:</label>
                    <small className="badge bg-danger">{error.tipo}</small> 
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 2 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
              <div className="form-floating mb-3 mt-3">
                    <select disabled className="form-control" name="marcas_id" id="marcas_id" placeholder="Marcas" onChange={handleInput}>
                    <option value={registerInput.marcas_id}>{registerInput.marcas_id}</option>
                      {listaMarcas.map((items) => (<option key={items.id} value={items.idf}>{items.marca}</option>))}
                    </select>
                    <label className="form-label">Marca Unidad:</label>
                    <small className="badge bg-danger">{error.marcas_id}</small> 
                  </div>
                  </div>

             
                  <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input disabled type="text" className="form-control" placeholder="Modelo" name="modelo" id="modelo"
                      onChange={handleInput} value={registerInput.modelo} />
                    <label className="form-label">Modelo:</label>
                    <small className="badge bg-danger">{error.modelo}</small> 
                    </div>
                </div>



                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input disabled type="text" className="form-control" placeholder="Año" name="año" id="año"
                      onChange={handleInput} value={registerInput.año} />
                    <label className="form-label">Año:</label>
                    <small className="badge bg-danger">{error.año}</small> 
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input disabled type="date" className="form-control" placeholder="Fecha Entrega"
                     name="fecha_entrega" id="fecha_entrega"
                      onChange={handleInput} value={registerInput.fecha_entrega} />
                    <label className="form-label">Fecha Entrega:</label>
                    <small className="badge bg-danger">{error.fecha_entrega}</small> 
                    </div>
                </div>
              </div>
            </div>

            {/* <!-- Multiple inputs 3 --> */}
            <div className="container-fluid">
              <div className="row">
              <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="flotas_id" id="id" 
                    placeholder="Patente" onChange={handleInput}>
                       <option defaultValue disable>Confirme Patente..</option>
                    <option value={registerInput.id} >{registerInput.patente}</option>
                      {/* {listaFlota.map((items) => (<option key={items.id} value={items.id}>{items.id}</option>))} */}
                    </select>
                    <label className="form-label">Patente en Taller:</label>
                    <small className="badge bg-danger">{error.flotas_id}</small> 
                  </div>
                </div>

               
                </div>
                </div>

                
             {/* <!-- Multiple inputs 4 --> */}
             <div className="container-fluid">
              <div className="row">

                <h2>Ingreso a Taller</h2>

              <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="date" className="form-control" placeholder="Ingreso a Taller" name="f_ingreso_taller"
                     id="f_ingreso_taller"  onChange={handleInput} value={registerInput.f_ingreso_taller} />
                    <label className="form-label">Ingreso a Taller:</label>
                    <small className="badge bg-danger">{error.f_ingreso_taller}</small>
                    </div>
                </div>
                <div className="col-sm-3 bg-ligh">
                <div className="form-floating mb-3 mt-3">
                    <input type="date" className="form-control" placeholder="Salida de Taller" name="f_salida_taller"
                      id="f_salida_taller"  onChange={handleInput} value={registerInput.f_salida_taller} />
                    <label className="form-label">Salida de Taller:</label>
                    <small className="badge bg-danger">{ error.f_salida_taller}</small> 
                    </div>
                </div>

                <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <select className="form-control" name="estados_id" id="estados_id" placeholder="Estado" onChange={handleInput}>
                    <option defaultValue disable>Elija Taller</option>
                    <option value={registerInput.estados_id} >{registerInput.estados_id}</option>
                      {listaEstados.map((items) => (<option key={items.id} value={items.id}>{items.estado}</option>))}
                    </select>
                    <label className="form-label">Estado:</label>
                    <small className="badge bg-danger">{error.estados_id}</small> 
                  </div>
                </div>

              <div className="col-sm-3 bg-ligh">
                  <div className="form-floating mb-3 mt-3">
                    <input type="text" className="form-control" placeholder="Observaciones" name="observacion"
                      onChange={handleInput} value={registerInput.observacion} />
                    <label>Observaciones (Opcional)</label>
                  </div>

                </div>

                </div>
              </div>


            <div className="d-flex">
  <div className="btn-group">
  <button type="button" className="btn"></button>
  <button type="submit" data-bs-toggle="tooltip" title="Editar Flota" className="btn btn-outline-secondary"><h3><FcAcceptDatabase/></h3></button>
  <button type="button" className="btn"></button>
  <button type="submit" className="btn btn-outline-secondary">
  <Link data-bs-toggle="tooltip" title="Volver" className="nav-link" to="/listFlota"><h3><FcDeleteDatabase/></h3></Link></button>
  <button type="button" className="btn"></button>
  
  </div>
</div>


          </div>
</form>
        </div>
      </div>

    </body>


  )
}