//import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import AuthUser from "./components/authUser";
import Guest from "./navbar/guest";
import Auth from "./navbar/auth";



function App() {
  const{getToken}=AuthUser();
  if(!getToken()){
    return <Guest />
  }
  return(
    <Auth />  
  ) 
}

export default App;
